<div class="container">
  <div class="content">

    <div class="backgroundTop">
      <h1>Welcome to the Digital Retrofit Assessor</h1>
      <div class="image-wrapper">
        <img src="./assets/images/housesImage.png" alt="Image">
      </div>
      <div class="b1-b">Reach EPC C</div>
      <div class="b1-b">Lower energy bills</div>
      <div class="b1-b">Boost your property value</div>
    </div>

    <div class="inputContainer">
      <h3>Tell us about your property</h3>
      <mat-form-field appearance="outline" floatLabel="always" class="postcodeFormField">
        <mat-label>Postcode</mat-label>
        <input matInput type="text" [(ngModel)]="searchTerm"
          pattern="[A-Za-z]{1,2}[0-9Rr][0-9A-Za-z]?(\s?[0-9][A-Za-z]{2})?" (input)="clearError()"
          (keyup.enter)="search()" (blur)="search()">
        <mat-icon matSuffix class="search-icon" *ngIf="!isLoading" (click)="search()">search</mat-icon>
        <mat-spinner matSuffix diameter="25" *ngIf="isLoading"></mat-spinner>
      </mat-form-field>
      <mat-form-field class="addressFormField" appearance="outline" floatLabel="always">
        <mat-label>Select Address</mat-label>
        <select matNativeControl [(ngModel)]="selectedProperty" [disabled]="propertyList.length === 0">
          <option *ngIf="!propertyList" disabled></option>
          <option *ngFor="let property of propertyList" [ngValue]="property">{{ property.address }}</option>
        </select>
      </mat-form-field>
    </div>

    <div class="buttonContainer">
      <button (click)="findMyHome()" class="main-button" [disabled]="!selectedProperty">Find my Property</button>
    </div>


  </div>
</div>