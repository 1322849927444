import { Component, OnInit,Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'app-property-suggestions-info',
  templateUrl: './property-suggestions-info.component.html',
  styleUrls: ['./property-suggestions-info.component.scss']
})
export class PropertySuggestionsInfoComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private router: Router) {}
  selectedPackage:any;
  totalSavings:number;

  ngOnInit() {
    const selectedPackage = sessionStorage.getItem('selectedPackage');
    if (selectedPackage) {
     this.selectedPackage = JSON.parse(selectedPackage);
     this.selectedPackage.improvementIds.sort((a: { cost: number; }, b: { cost: number; }) => b.cost - a.cost);
   } else {
     alert('ERROR');
     this.router.navigate(['/home']);

  }
}

ngAfterViewInit() {
  setTimeout(() => {
    let totalSavings = 0;
    for (const improvement of this.selectedPackage.improvementIds) {
      totalSavings += improvement.savings.reductionsInAnnualFuelBill;
    }

    this.totalSavings = totalSavings;
  }, 0);
}

  bookSurvey() {
    let newArray = [];
    for (let i = 0; this.selectedPackage.improvementIds.length > i; i++) {
      newArray.push(this.selectedPackage.improvementIds[i].id);
    }

    let flattenedArray = [].concat(...newArray);
    sessionStorage.setItem('improvementIds', JSON.stringify(flattenedArray));
    sessionStorage.setItem('objectiveId', this.selectedPackage.objective.id);
    this.router.navigate(['contact-details'])
  }
}
 