import { gql } from 'apollo-angular';

const LOOKUP_ADDRESS_FOR_POSTCODE = gql`
  query LookUpAddressesForPostCode($postcode: String!) {
    lookUpAddressesForPostCode(postcode: $postcode) {
      propertyId
      address
    }
  }
`;

const COMPONENT = `                
summaryText
components {
  currentSelectionConfidence
  currentSelectionId
  currentSelectionValue
  description
  identifier
  title
    options {
        selectionId
        value
    }
}`;

const GET_PROPERTY_DETAILS = gql`
query GetPropertyDetails($propertyId: String!) {
getPropertyDetails(propertyId: $propertyId) {
  propertyId
  shortAddress
  postcode
  keyStats {
    annualFuelBill
    currentEpcRating
    propertyAge
    propertyDetachment
    propertyType
    sapScore
    totalCo2EmissionsKg
    totalFloorArea
  }
  iconMedia {
    desktop
    mobile
  }
  propertyConstruction {
      basics {
          summaryText
          ${COMPONENT}
      }
      walls {
        summaryText
        ${COMPONENT}
    }
      floor {
        summaryText
        ${COMPONENT}
    }
      roof {
        summaryText
        ${COMPONENT}
    }
      heating {
        summaryText
        ${COMPONENT}
    }
      windowsAndDoors {
        summaryText
        ${COMPONENT}
    }
      renewables {
        summaryText
        ${COMPONENT}
    }
      other {
        summaryText
        ${COMPONENT}
    }
  }
}
}
  `;

const GET_IMPROVEMENTS = gql`
  query GetImprovements($propertyId: String!) {
    getImprovements(propertyId: $propertyId) {
      propertyId
      packages {
        improvementIds
        objective {
          id
          name
          tagLine
        }
      }
      improvements {
        cost
        description
        iconMedia {
          desktop
          mobile
        }
        id
        name
        savings {
          increaseInSapScore
          newEpcRating
          reductionCo2EmissionsKg
          reductionsInAnnualFuelBill
        }
      }
    }
  }
`;

const GET_LIST_IMPROVEMENT_OBJECTIVES = gql`
  query GetImprovements {
    getListImprovementObjectives(propertyId: $propertyId) {
      name
      id
      tagLine
    }
  }
`;

const RECORD_SURVEY_BOOKING = gql`
mutation RecordSurveyBooking(
  $transactionId: String!
  $userId: String!
  $propertyId: String!
  $morning: Boolean!
  $afternoon: Boolean!
  $emailOk: Boolean!
  $contactOk: Boolean!
  $phoneNumber: String!
  $objectiveId: Int!
  $improvementIds: [Int!]!
) {
  recordSurveyBooking(
    booking: {
      transactionId: $transactionId
      userId: $userId
      propertyId: $propertyId
      preferences: {
        timeOfDay: { morning: $morning, afternoon: $afternoon }
        contact: {
          emailOk: $emailOk
          telephone: { contactOk: $contactOk, phoneNumber: $phoneNumber }
        }
      }
      package: { objective: { id: $objectiveId }, improvementIds: $improvementIds }
    }
  ) {
    success
    message
  }
}
`;




export {
  LOOKUP_ADDRESS_FOR_POSTCODE,
  GET_PROPERTY_DETAILS,
  GET_IMPROVEMENTS,
  GET_LIST_IMPROVEMENT_OBJECTIVES,
  RECORD_SURVEY_BOOKING
};
