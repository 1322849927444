<script type="text/javascript" async src="https://bot.leadoo.com/bot/visual.js?code=4ef5d3dd#seamless"></script>    
<div class="container">
  <div class="content">

    <div class="icon-and-address">
      <mat-icon (click)="back()" class="back-arrow">arrow_back</mat-icon>
      <div class="topAddress" *ngIf="propertyDetails?.shortAddress || propertyDetails?.postcode">
        <mat-icon class="locationIcon">location_on</mat-icon>
        <span class="b1-b locationText">{{propertyDetails?.shortAddress}}, {{propertyDetails?.postcode}}</span>
      </div>
    </div>

    <div class="inputContainer">
      <h1>Book a survey</h1>
      <ld-slot name="Web App Final Step Bot" ></ld-slot>
    </div>
  </div>
</div>
