<nav class="navbar">

    <div class="navbar-icon">
        <img alt="Q-Bot LOGO" class="logoImage" src="./assets/images/logoLaunch.png">
    </div>

    <div class="navInfo">
        <div class="navInfoText">Powered by Parity</div>
        <div class="navInfoText">{{version}}</div>

    </div>

  </nav>
  
  <div class="page-content">
    <router-outlet></router-outlet>
  </div>
