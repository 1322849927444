
<div class="inputContainer">
  <h3>{{selectedPackage.objective?.name}}
    <mat-icon class="closeIcon" [mat-dialog-close]>close</mat-icon></h3> 
    <h5>{{selectedPackage.objective?.tagLine}}</h5>
  <div>
    <mat-dialog-content>
      <div class="benefitsContainer">
        <div *ngFor="let packageDetail of selectedPackage.improvementIds" class="row">
          <div class="first-column">
            <img src="{{ packageDetail.iconMedia.mobile}}" class="packageIcon" alt="packageIcon">
            <div class="text-start">
              <p class="l2-b">{{packageDetail.name}}</p>
              <p class="small-text">
                <mat-icon class="energyIcon">energy_savings_leaf</mat-icon>£{{packageDetail.savings.reductionsInAnnualFuelBill}}/year
                <br class="textbreak">
                <mat-icon class="energyIcon">co2</mat-icon>{{packageDetail.savings.reductionCo2EmissionsKg}} kg/year
              </p> 
            </div>
          </div>
          <div class="text-column">
            <p class="big-text l2-b">From £{{packageDetail.cost}}</p>
          </div>
        </div>
        <div class="savings">
          <h5 >Savings estimation: <strong>£{{totalSavings}}/year</strong> *</h5>
        </div>
        <p class="l3-b">Already installed a measure?</p>
        <p class="b3-r">Great! Our surveyor will be able to advise on other suitable improvements and provide you with a new EPC certificate.</p>
        <p class="b3-r">* The savings figures presented are estimates and may vary based on different circumstances and usage patterns. Actual results may differ.</p> 

      </div>     
    </mat-dialog-content>
  </div>
</div>

<div class="buttonContainer">
  <button (click)="bookSurvey()" mat-dialog-close class="main-button">Book a survey</button>
</div>