<div class="container">
 
  <ng-template #loadingSpinner>
    <div style="text-align: center;" class="content">
      <div class="icon-and-address">
        <mat-icon (click)="back()" class="back-arrow">arrow_back</mat-icon>
        <div class="topAddress">
          <mat-icon class="locationIcon">location_on</mat-icon>
          <span class="b1-b locationText">{{selectedProperty?.address}}</span>
        </div>
      </div>
      <h3 [@fadeInOut]="spinnerText" class="spinnerText">{{ spinnerText }}</h3>
      <div class="spinner-container">
        <div class="spinner"></div>
      </div>
    </div>
  </ng-template>
  

  <div class="content" *ngIf="propertyDetails; else loadingSpinner">

    <div class="icon-and-address">
      <mat-icon (click)="back()" class="back-arrow">arrow_back</mat-icon>
      <div class="topAddress" *ngIf="propertyDetails?.shortAddress || propertyDetails?.postcode">
        <mat-icon class="locationIcon">location_on</mat-icon>
        <span class="b1-b locationText">{{propertyDetails?.shortAddress | titlecase}}, {{propertyDetails?.postcode}}</span>
      </div>
    </div>
    
    <div class="inputContainer">
      <h3>Your Property Summary</h3>
      <div class="summaryContainer">
        <div class="left-column">
          <img class="propertyImage" src="{{propertyDetails?.iconMedia.mobile}}" alt="houseImage">
        </div>
        <div class="right-column">
          <div  class="propertyStats">
            <mat-icon class="propertySummaryIcon">house</mat-icon>
            <span class="b1-r">{{propertyDetails?.keyStats.propertyType}} </span>
          </div>
          <div class="propertyStats">
            <mat-icon class="propertySummaryIcon">grid_on</mat-icon>
            <span class="b1-r">{{propertyDetails?.keyStats.propertyDetachment}}</span>
          </div>
          <div class="propertyStats">
            <mat-icon class="propertySummaryIcon">construction</mat-icon>
            <span class="b1-r">{{propertyDetails?.keyStats.propertyAge}}</span>
          </div>
          <div class="propertyStats">
           <span class="l1-b" (click)="viewPropertyDetails()">View all details</span>
          </div>
        </div>
      </div>
    </div>

    <div class="inputContainer">
      <h3>Your Current Energy Rating</h3>
      <div class="summaryContainer">
        <div class="left-column">
            <img class="epcGraphImage" src="./assets/images/epcgraph.png" alt="epcGraph">
        </div>
        <div class="right-column">
            <p class="epcText" [ngClass]="{
                'color-a': epcRating >= 91,
                'color-b': epcRating >= 81 && epcRating <= 90,
                'color-c': epcRating >= 69 && epcRating <= 80,
                'color-d': epcRating >= 55 && epcRating <= 68,
                'color-e': epcRating >= 39 && epcRating <= 54,
                'color-f': epcRating >= 21 && epcRating <= 38,
                'color-g': epcRating <= 20 
              }">
                <span class="epcNumber">{{ epcRating }}</span> 
                <span class="epcLetter">{{ getBandLetter(epcRating) }}</span>
            </p>
        </div>
    </div>
    </div>

    <div class="buttonContainer">
      <button (click)="getSuggestions()" class="main-button">Find suitable improvements </button>
    </div>
    

</div>