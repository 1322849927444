<div class="container">

  <ng-template #loadingSpinner>
    <div style="text-align: center;" class="content">
      <div class="icon-and-address">
        <mat-icon (click)="back()" class="back-arrow">arrow_back</mat-icon>
        <div class="topAddress">
          <mat-icon class="locationIcon">location_on</mat-icon>
          <span class="b1-b locationText">{{propertyDetails?.shortAddress | titlecase}}, {{propertyDetails?.postcode}}</span>
        </div>
      </div>
      <h3 [@fadeInOut]="spinnerText" class="spinnerText">{{ spinnerText }}</h3>
      <div class="spinner-container">
        <div class="spinner"></div>
      </div>
    </div>
  </ng-template>

  <div class="content" *ngIf="propertyImprovements; else loadingSpinner">

    <div class="icon-and-address">
      <mat-icon (click)="back()" class="back-arrow">arrow_back</mat-icon>
      <div class="topAddress" *ngIf="propertyDetails?.shortAddress || propertyDetails?.postcode">
        <mat-icon class="locationIcon">location_on</mat-icon>
        <span class="b1-b locationText">{{propertyDetails?.shortAddress | titlecase}}, {{propertyDetails?.postcode}}</span>
      </div>
    </div>

    <div class="inputContainer">
      <p class="b1-r">Your Current Energy Rating</p>
      <div class="summaryContainer">
        <div class="left-column">
            <img class="epcGraphImage" src="./assets/images/epcgraph.png" alt="houseImage">
        </div>
        <div class="right-column">
            <p class="epcText" [ngClass]="{
                'color-a': epcRating >= 91,
                'color-b': epcRating >= 81 && epcRating <= 90,
                'color-c': epcRating >= 69 && epcRating <= 80,
                'color-d': epcRating >= 55 && epcRating <= 68,
                'color-e': epcRating >= 39 && epcRating <= 54,
                'color-f': epcRating >= 21 && epcRating <= 38,
                'color-g': epcRating <= 20 
              }">
                <span class="epcNumber">{{ epcRating }}</span> 
                <span class="epcLetter">{{ getBandLetter(epcRating) }}</span>
            </p>
        </div>
    </div>
    
    </div>

    <div class="inputContainer">
      <p class="b1-r">Good news! We found suitable energy improvements for your property. Book a survey to get a quote.</p>

      <div *ngFor="let package of propertyPackages; let first = first; let i = index" 
      [ngClass]="{'highlighted': package.highlighted && ( i === selectedIndex), 'grey-bg': !package.highlighted}" 
      (click)="toggleHighlight(package, i)" class="card">
        <div class="column">
          <div *ngFor="let packageInstance of package?.improvementIds.sort(sortPackageInstances).slice(0, 2); let last = last;" class="row">
            <div class="icon">
                <mat-icon>check</mat-icon>
            </div>
            <img src="{{ packageInstance.iconMedia.mobile }}" class="packageIcon" alt="packageIcon">
            <p class="l2-b text-start" style="width: 100%;">{{ packageInstance.name }}</p>
        </div>        
          <div *ngIf="package?.improvementIds.length > 2" class="row">
            <div class="icon">
              <mat-icon>check</mat-icon>
            </div>
            <p (click)="viewPackageDetails(package)" class="l2-b text-start" style="width: 100%; cursor: pointer;">+({{ package?.improvementIds.length - 2 }}) more</p>
          </div>
        </div>
        <div class="column">
          <div class="row">
            <div class="text-column">
              <p class="big-text l2-b">{{ package.objective.name }}</p>
            </div>
          </div>
          <div class="row">
            <button (click)="viewPackageDetails(package)" class="centered-button">See details</button>
          </div>
          <div class="row">
            <div class="text-right">
              <p class="tagLine" [ngClass]="{'grey-background': !package.highlighted, 'blue-background': package.highlighted}">{{ package.objective.tagLine }}</p>
              <p class="bold-text">From £{{ getTotalCost(package) }}</p>
            </div>
          </div>
        </div>
      </div>
      
    </div>

    <div class="buttonContainer">
      <button class="main-button" (click)="bookSurvey()">Book a Survey</button>
    </div>

  </div>
</div>