import { ApolloModule } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';
import { NgModule } from '@angular/core';
import { ApolloClientOptions, InMemoryCache } from '@apollo/client/core';
import { environment } from 'src/environments/environment';
import { APOLLO_NAMED_OPTIONS, NamedOptions } from 'apollo-angular';

const uri = environment.graphql.publicEndpoint; 
const uriPrivate = environment.graphql.privateEndpoint; 


export function createApollo(httpLink: HttpLink): ApolloClientOptions<any> {
  return {
    link: httpLink.create({ uri }),
    cache: new InMemoryCache(),
  };
}

@NgModule({
  exports: [ApolloModule],
  providers: [
    {
      provide: APOLLO_NAMED_OPTIONS, // <-- Different from standard initialization
      useFactory(httpLink: HttpLink): NamedOptions {
        return {
          default:  {
            cache: new InMemoryCache(),
            link: httpLink.create({
              uri: uri,
            }),
          },
          privateEndpoint: {
            cache: new InMemoryCache(),
            link: httpLink.create({
              uri: uriPrivate,
            }),
          },
        };
      },
      deps: [HttpLink],
    },
  ],

})
export class GraphQLModule {}
