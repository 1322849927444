import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Apollo } from 'apollo-angular';
import { GET_PROPERTY_DETAILS } from '../../shared/graphql/graphql.queries';
import { PropertyDetailsInfoComponent } from '../property-details-info/property-details-info.component';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { interval, Subscription } from 'rxjs';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';

@Component({
  selector: 'app-property-details',
  templateUrl: './property-details.component.html',
  styleUrls: ['./property-details.component.scss'],
  animations: [
    trigger('fadeInOut', [
      state('void', style({ opacity: 0 })),
      transition('void <=> *', animate('500ms ease-in-out')),
    ])
  ]
  
})

export class PropertyDetailsComponent implements OnInit {
  selectedProperty: any;
  propertyDetails: any;
  propertyConstructionDetails: { key: string; value: any }[] = [];
  epcRating: number;
  objectives: any;
  isLoading: boolean = true;
  spinnerText: string;
  texts: string[] = [
    'Searching for your property... ',
    'Fetching details...',
    'Almost there...',
    'Hang tight, we\'re searching...'
  ];
  
  textIndex: number = 0;
  intervalSubscription: Subscription;
  retryCount: number = 0
  constructor(private router: Router, private apollo: Apollo, private dialog: MatDialog) {}

  ngOnInit() {
    const selectedPropertyJSON = sessionStorage.getItem('selectedProperty');
    if (selectedPropertyJSON) {
      this.selectedProperty = JSON.parse(selectedPropertyJSON);
    } else {
      alert('Please select a property');
      this.router.navigate(['/home']);
    }
    this.spinnerText = this.texts[0];
    this.startTextChangeInterval();
  }

  ngOnDestroy(): void {
    this.stopTextChangeInterval();
  }

  startTextChangeInterval(): void {
    this.intervalSubscription = interval(3000).subscribe(() => {
      this.textIndex = (this.textIndex + 1) % this.texts.length;
      this.spinnerText = this.texts[this.textIndex].replace('{{selectedProperty.address}}', this.selectedProperty.address);
    });
  }

  stopTextChangeInterval(): void {
    if (this.intervalSubscription) {
      this.intervalSubscription.unsubscribe();
    }
  }

  ngAfterViewInit() {
    const maxRetries = 2;
  
    this.apollo
      .query({
        query: GET_PROPERTY_DETAILS,
        variables: {
          propertyId: this.selectedProperty.propertyId,
        },
      })
      .pipe(
        retry(maxRetries),
        catchError((error: any) => {
          console.error('GraphQL Error:', error);
          if (error) {
            alert('An error occurred while fetching data from the server. Please try again later.');
          } 
          this.router.navigate(['/home']);
          return throwError(error);
        })
      )
      .subscribe({
        next: (result: any) => {
          this.propertyDetails = result?.data?.getPropertyDetails;
          this.isLoading = false;
          this.epcRating = Math.round(this.propertyDetails.keyStats.sapScore);
          sessionStorage.setItem('propertyDetails', JSON.stringify(this.propertyDetails));
          this.propertyConstructionDetails = [];
          for (const key in this.propertyDetails?.propertyConstruction) {
            if (
              key !== '__typename' &&
              this.propertyDetails?.propertyConstruction.hasOwnProperty(key)
            ) {
              this.propertyConstructionDetails.push({
                key: this.transformKeyString(key),
                value: this.propertyDetails?.propertyConstruction[key],
              });
            }
          }
          sessionStorage.setItem('propertyConstructionDetails', JSON.stringify(this.propertyConstructionDetails));
        },
      });
  }

  transformKeyString(key: string): string {
    return key
      .replace(/([A-Z])/g, ' $1')
      .replace(/^./, (str) => str.toUpperCase());
  }

  getBandLetter(epcRating: number) {
    if (epcRating >= 91 && epcRating <= 100) {
      return 'A';
    } else if (epcRating >= 81 && epcRating <= 91) {
      return 'B';
    } else if ( epcRating >= 69 && epcRating <= 80) {
      return 'C';
    } else if (epcRating >= 55 && epcRating <= 68) {
      return 'D';
    } else if (epcRating >= 39 && epcRating <= 54) {
      return 'E';
    } else if (epcRating >= 21 && epcRating <= 38) {
      return 'F';
    } else if (epcRating <= 20) {
      return 'G';
    } else return
  }
 
  viewPropertyDetails() {
    const screenWidth = window.innerWidth;
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = screenWidth < 1000 ? '80%' : '50%';
    dialogConfig.height = screenWidth < 1000 ? '80%' : '60%';
    this.dialog.open(PropertyDetailsInfoComponent, {
      data: {
        propertyConstructionDetails: this.propertyConstructionDetails,
      },
    });
  }


  getSuggestions() {
    this.router.navigate(['/property-suggestions']);
  }

  back() {
    this.router.navigate(['/home']);
    sessionStorage.removeItem('propertyDetails');

  }

}
