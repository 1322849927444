import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Apollo } from 'apollo-angular';
import { LOOKUP_ADDRESS_FOR_POSTCODE } from '../../shared/graphql/graphql.queries';

@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.scss'],
})
export class LandingPageComponent implements OnInit {
  constructor(private router: Router, private apollo: Apollo) {}

  propertyList: any[] = [];
  loading = true;
  error: any;
  searchTerm: string;
  selectedProperty: any;
  isInvalidPostcode = false;
  isLoading: boolean = false;

  ngOnInit() {
    sessionStorage.removeItem('selectedProperty');
  }

  search() {
    const postcodePattern = /^[A-Za-z]{1,2}[0-9Rr]\d?[0-9A-Za-z]?(\s?\d[A-Za-z]{2})?$/;

    if (!postcodePattern.test(this.searchTerm)) {
      this.isInvalidPostcode = true;
      return;
    }

    this.isInvalidPostcode = false;
    this.isLoading = true;

    this.apollo
      .query({
        query: LOOKUP_ADDRESS_FOR_POSTCODE,
        variables: {
          postcode: this.searchTerm,
        },
      })
      .subscribe((result: any) => {
        this.propertyList = result?.data?.lookUpAddressesForPostCode;
        this.loading = result.loading;
        this.error = result.error;
        this.isLoading = false;
      });
  }

  clearError() {
    this.isInvalidPostcode = false;
  }

  findMyHome() {
    sessionStorage.setItem('selectedProperty',JSON.stringify(this.selectedProperty));
    this.router.navigate(['/property-details']);
  }

}
