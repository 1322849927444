<div class="inputContainer">
    <h3>Property Details <mat-icon class="closeIcon" [mat-dialog-close]>close</mat-icon></h3> 
    <div>
      <mat-dialog-content>
        <div class="propertyDet">
            <div *ngFor="let construction of data.propertyConstructionDetails;let isLast = last; let i = index">
              <div class="propertyDetails">
                <div class="properyDetailTitle l1-b">{{ construction.key }}</div>
                <div class="propertyComponentSummary">{{ construction.value.summaryText }}</div>
                <div *ngFor="let component of construction.value.components">
                  <div class="b1-r">{{component.title}}:<b style="font-weight: 500;" class="componentSelectionValue">{{ component.currentSelectionValue }}</b>
                </div>
                </div>
                <hr class="separator" *ngIf="!isLast">
              </div> 
            </div>
          </div>
      </mat-dialog-content>
    </div>
  </div>

  <div class="buttonContainer">
    <button (click)="getSuggestions()" [mat-dialog-close] class="main-button">Find suitable improvements</button>
  </div>