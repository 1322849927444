import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Apollo } from 'apollo-angular';

@Component({
  selector: 'app-contact-details',
  templateUrl: './contact-details.component.html',
  styleUrls: ['./contact-details.component.scss'],
})
export class ContactDetailsComponent implements OnInit {
  propertyDetails: any;
  selectedProperty:any;
  constructor(private router: Router,
    private apollo: Apollo) {}

    loading: boolean = false;
    transactionId: string;
    afternoon: boolean = false;
    morning:boolean = false;
    emailOk = true;
    contactOk = false;
    phoneNumber = '';
    improvementIds: any; 
    objectiveId: any;

    userInfo = {
      emailEnabled: true,
      phoneEnabled: false,
      email: '', 
    };
  
    updateEmailOk() {
      this.emailOk = this.userInfo.emailEnabled;
    }
  
    updateContactOk() {
      this.contactOk = this.userInfo.phoneEnabled;
    }

  showInput: boolean = false;

  showInputField() {
    this.showInput = true;
  }

  hideInputField() {
    this.showInput = false;
  }
  packageName: any;
  propertyPackages:any;

  ngOnInit() {
    window.scrollTo(0, 0);

    //get property address 
    const propertyDetailsString = sessionStorage.getItem('propertyDetails');
    if (propertyDetailsString) {
      this.propertyDetails = JSON.parse(propertyDetailsString);
    } else {
      alert('Please select a property');
      this.router.navigate(['/home']);

    }
    sessionStorage.setItem('c_selectedProperty', this.propertyDetails.shortAddress + ' - ' + this.propertyDetails.postcode)    

    //get selected package 
    const packageNameString = sessionStorage.getItem('selectedPackage')
    if (packageNameString){
      this.packageName = packageNameString
      sessionStorage.setItem('c_selectedPackage', JSON.parse(packageNameString).objective.name)

    }

    const propertyPackagesString = sessionStorage.getItem('propertyPackages')

    //interface and function to strip unnecessary json objects, kept in the structure: cost, name, id
    interface Improvement {
      cost: number;
      id: number;
      name: string;
  }
  
  interface ImprovementPackage {
      objective: {
          name: string;
      };
      improvementIds: Improvement[];
  }
  
  if (propertyPackagesString) {
      const propertyPackages: ImprovementPackage[] = JSON.parse(propertyPackagesString);
      
      const c_propertyPackages: { [key: string]: Improvement[] } = {};
      
      propertyPackages.forEach((packagex: ImprovementPackage) => {
          const packageName = packagex.objective.name;
          
          if (!c_propertyPackages[packageName]) {
              c_propertyPackages[packageName] = [];
          }
          
          packagex.improvementIds.forEach((improvement: Improvement) => {
              const { cost, id, name } = improvement;
              
              c_propertyPackages[packageName].push({ cost, id, name });
          });
      });
      
      // store the grouped improvements object in sessionStorage
      sessionStorage.setItem('c_propertyPackages', JSON.stringify(c_propertyPackages));
  }
  
  

    const improvementIdsString = sessionStorage.getItem('improvementIds');
    const objectiveIdString = sessionStorage.getItem('objectiveId');
    this.improvementIds = improvementIdsString ? JSON.parse(improvementIdsString) : null;
    this.objectiveId = objectiveIdString ? parseInt(objectiveIdString) : null;

    

  }
  
  back() {
    this.router.navigate(['property-suggestions']);
  }

  selectedButton: number | null = null;

  selectedButtons: number[] = [];

  isSelected(buttonNumber: number): boolean {
    return this.selectedButtons.includes(buttonNumber);
  }

  toggleButton(buttonNumber: number) {
    const index = this.selectedButtons.indexOf(buttonNumber);
  
    if (index !== -1) {
      this.selectedButtons.splice(index, 1);
    } else {
      this.selectedButtons.push(buttonNumber);
    }
  
    if (buttonNumber === 1) {
      this.morning = !this.morning;
    } else if (buttonNumber === 2) {
      this.afternoon = !this.afternoon;
    }
  }
  
  
}
