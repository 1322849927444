import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Apollo } from 'apollo-angular';
import { GET_IMPROVEMENTS } from '../../shared/graphql/graphql.queries';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { PropertySuggestionsInfoComponent } from '../property-suggestions-info/property-suggestions-info.component';
import { interval, Subscription } from 'rxjs';
import { trigger, state, style, animate, transition } from '@angular/animations';

@Component({
  selector: 'app-property-suggestions',
  templateUrl: './property-suggestions.component.html',
  styleUrls: ['./property-suggestions.component.scss'],
  animations: [
    trigger('fadeInOut', [
      state('void', style({ opacity: 0 })),
      transition('void <=> *', animate('500ms ease-in-out')),
    ])
  ]
})
export class PropertySuggestionsComponent implements OnInit {
  property: any;
  propertyDetails: any;
  propertyImprovements: any;
  propertyPackages: any = [];
  propertyPackagesRaw: any;
  impIds:any;
  retrofitMeasures: any;
  currentMeasures: any;
  loading = true;
  error: any;
  isLoading: boolean = false;
  epcRating: number;
  spinnerText: string;
  texts: string[] = [
    'Searching for suitable improvements... ',
    'Fetching details...',
    'Almost there...',
    'Hang tight, we\'re searching...'
  ];
  selectedIndex: number = 0;
  textIndex: number = 0;
  intervalSubscription: Subscription;
  constructor(
    private router: Router,
    private apollo: Apollo,
    private dialog: MatDialog
  ) {}

  ngOnInit() {
    
    const propertyDetails = sessionStorage.getItem('propertyDetails');
    if (propertyDetails) {
      this.propertyDetails = JSON.parse(propertyDetails);

    } else {
      alert('Please select a property');
      this.router.navigate(['/home']);
    }

    this.apollo
      .query({
        query: GET_IMPROVEMENTS,
        variables: {
          propertyId: this.propertyDetails.propertyId,
        },
      })
      .subscribe((result: any) => {
        this.propertyImprovements = result?.data?.getImprovements.improvements;
        this.propertyPackagesRaw = result?.data?.getImprovements.packages;
        this.loading = result.loading;
        this.epcRating = Math.round(this.propertyDetails.keyStats.sapScore)
        this.error = result.error;
        this.isLoading = false;

        if (result) {
          this.matchImprovementIds();
          this.highlightFirstPackage()

        }
      });
      this.spinnerText = this.texts[0];
      this.startTextChangeInterval();
    }
  
    ngOnDestroy(): void {
      this.stopTextChangeInterval();
    }

    highlightFirstPackage(){
      this.propertyPackages[0].highlighted = true;

    }
  
    startTextChangeInterval(): void {
      this.intervalSubscription = interval(3000).subscribe(() => {
        this.textIndex = (this.textIndex + 1) % this.texts.length;
      });
    }
  
    stopTextChangeInterval(): void {
      if (this.intervalSubscription) {
        this.intervalSubscription.unsubscribe();
      }
    
  }

  getBandLetter(epcRating: number) {
    if (epcRating >= 91 && epcRating <= 100) {
      return 'A';
    } else if (epcRating >= 81 && epcRating <= 91) {
      return 'B';
    } else if (epcRating >= 69 && epcRating <= 80) {
      return 'C';
    } else if (epcRating >= 55 && epcRating <= 68) {
      return 'D';
    } else if (epcRating >= 39 && epcRating <= 54) {
      return 'E';
    } else if (epcRating >= 21 && epcRating <= 38) {
      return 'F';
    } else if (epcRating <= 20) {
      return 'G';
    } else return;
  }


  getTotalCost(packagex: any) {
    let totalCost = 0;
    for (const packageInstance of packagex.improvementIds) {
      totalCost += packageInstance.cost;
    }

    return totalCost;

  }
  
  matchImprovementIds() {
    const thirdArray = this.propertyPackagesRaw.map(
      (pkg: { improvementIds: string | any[] }) => ({
        ...pkg,
        improvementIds: this.propertyImprovements.filter(
          (item: { id: string }) => pkg.improvementIds.includes(item.id)
        ),
        
      })
    );

    this.impIds = thirdArray.improvementIds
    this.propertyPackages = thirdArray;
    sessionStorage.setItem(
      'propertyPackages',
      JSON.stringify(this.propertyPackages)
    );
  }
  
  sortPackageInstances(a: any, b: any): number {
    return b.cost - a.cost;
}


  sortMeasures(sortOrder: any) {
    if (sortOrder === 'asc') {
      this.retrofitMeasures.sort((a: any, b: any) => a.cost - b.cost);
    } else if (sortOrder === 'desc') {
      this.retrofitMeasures.sort((a: any, b: any) => b.cost - a.cost);
    }
  }

  viewPackageDetails(packageInstance: any) {
    const screenWidth = window.innerWidth;
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = screenWidth < 1000 ? '80%' : '50%';
    dialogConfig.height = screenWidth < 1000 ? '80%' : '60%';
    this.dialog.open(PropertySuggestionsInfoComponent);
    sessionStorage.setItem('selectedPackage', JSON.stringify(packageInstance));
  }

  bookSurvey() {
    let string = JSON.stringify(sessionStorage.getItem('selectedProperty'))
    this.router.navigate(['contact-details']);

  }

  toggleHighlight(packagex: any, index: number) {
    this.propertyPackages[this.selectedIndex].highlighted = false;
    this.propertyPackages[0].highlighted = false
  
    packagex.highlighted = true;
  
    this.selectedIndex = index;
    sessionStorage.setItem('selectedPackage', JSON.stringify(packagex));

  }


  back() {
    this.router.navigate(['property-details']);
  }
}
