
const getEndpointUrl = () => {
  return new URL(`${window.location.protocol}//api.${window.location.hostname}`).origin
}


export const environment = {
    production: false,
    cognito: {
      region: 'your-cognito-region',
      userPoolId: 'your-user-pool-id',
      appClientId: 'your-app-client-id',
    },
    graphql: {
        publicEndpoint: `https://api.drasandbox-napier.q-bot.co/retrofit/public/graphql`,
        privateEndpoint: `${getEndpointUrl()}/retrofit/private/graphql`,
      },
  };
