import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'app-property-details-info',
  templateUrl: './property-details-info.component.html',
  styleUrls: ['./property-details-info.component.scss']
})
export class PropertyDetailsInfoComponent {
   

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,private router: Router) {}

  getSuggestions() {
    this.router.navigate(['/property-suggestions']);
  
  }

}
